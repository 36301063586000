<template>
  <div>
    <v-row>
      <v-col 
        v-if="acceptFormat !== 'pdfOnly'" 
        :xl="singleImage ? '4' : '12'" 
        :lg="singleImage ? '4' : '12'"
        :md="singleImage ? '6' : '12'" 
        :sm="singleImage ? '6' : '12'" 
        cols="12"
      >
        <div class="image-upload-display">
          <v-img 
            v-if="imgList.length <= 0" 
            :src="require('@/assets/empty-image.svg')" 
            :max-height="150"
            :max-width="250"
          ></v-img>
          <template v-else>
            <div v-for="(img, index) of imgList" :key="index">
              <v-img 
                class="preview-img" 
                :src="getImage(img)" 
                :max-height="150" 
                :max-width="250"
                @click="previewImg(index)"
              ></v-img>
              <div class="d-flex justify-space-between mt-1">
                <v-btn  depressed small text color="error" @click="removeImage(index)" :disabled="disableUpload">刪除</v-btn>
              </div>
            </div>
          </template>
        </div>
      </v-col>
      <v-col 
        :xl="singleImage && acceptFormat !== 'pdfOnly' ? '4' : '12'"
        :lg="singleImage && acceptFormat !== 'pdfOnly' ? '4' : '12'"
        :md="singleImage && acceptFormat !== 'pdfOnly' ? '6' : '12'"
        :sm="singleImage && acceptFormat !== 'pdfOnly' ? '6' : '12'" 
        cols="12"
      >
        <v-file-input 
          v-show="false" 
          :id="fileInputKey" 
          :accept="fileFormatStr"
          @change="getUploadFile($event, fileInputKey)" 
          :disabled="uploading || disableUpload"
        ></v-file-input>

        <v-btn 
          color="primary" 
          depressed 
          @click="_triggerUploadFile()" 
          :disabled="uploading" 
          :loading="uploading"
        > {{ uploadText }} </v-btn>

        <v-btn 
          v-if="acceptFormat === 'pdfOnly' && imgList.length > 0" 
          depressed text small
          color="secondary"  
          class="ml-4"
          @click="previewImg(0)"
        >
          <v-icon class="mr-1" small>{{ mdiEyeOutline }}</v-icon>
          查看
        </v-btn>
        <v-btn 
          v-if="acceptFormat === 'pdfOnly' && imgList.length > 0" 
          depressed text small
          color="error"  
          class="ml-4"
          @click="removeImage(0)"
        >
          <v-icon class="mr-1" small>{{ mdiTrashCanOutline }}</v-icon>
          刪除
        </v-btn>
      
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiPlus, mdiEyeOutline, mdiTrashCanOutline } from '@mdi/js';

export default {
  name: 'ImageUploader',
  props: {
    fileInputKey: {
      type: String,
      default: 'upload_file',
      required: true,
    },
    imgList: {
      type: Array,
      default: () => [], // Array<string>
      required: true,
    },
    singleImage: {
      type: Boolean,
      default: false,
    },
    uploadText: {
      type: String,
      default: '上載圖片',
    },
    acceptFormat: {
      type: String,
      default: 'image',
      required: true,
    },
    disableUpload: {
      type: Boolean,
      default: false,
    },
    dispatchUpdateOnChange: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    uploading() {
      return this.$store.getters.isUploading
    },
    fileFormatStr() {
      return this.$common.getAcceptFileFormat(this.acceptFormat).formatStr;
    },
    fileFormatArr() {
      return this.$common.getAcceptFileFormat(this.acceptFormat).formatArr;
    }
  },
  data: () => ({
    mdiTrashCanOutline,
    mdiPlus,
    mdiEyeOutline,
    addDesDialogOpen: false,
    newDes: '',
    addDesIndex: -1,
  }),
  methods: {
    _triggerUploadFile() {
      if (this.$store.getters.isLoading || this.$store.getters.uploading) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'processing',
          type: 'error',
          refresh: false,
          redirect: ''
        });
        return;
      }
      if (this.$validate.DataValid(this.fileInputKey) && document.getElementById(this.fileInputKey)) {
        document.getElementById(this.fileInputKey).click();
      }
    },
    async getUploadFile(file) {
      if (file !== undefined && file !== null) {
        const check = this.$validate.validateFileInput(file, this.fileFormatArr);
        if (this.$validate.DataValid(check)) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: check,
            type: 'error',
            refresh: false,
            redirect: '',
          })
        } else {
          this.$store.dispatch('setUploadLoading', true);
          this.$store.dispatch('controlUploadAlert', { show: true, progress: 0 });

          let postData = {
            'upload_image': { forceJPG: true },
            file,
          }

          if (file.type === 'application/pdf') {
            postData = {
              upload_doc: {},
              file,
            }
          } else if (file.type === 'video/mp4' || file.type === 'video/webm') {
            postData = {
              upload_video: {},
              file,
            }
          } else if (file.type === 'image/svg' || file.type === 'image/svg+xml') {
            postData = {
              upload_vector: {},
              file,
            }
          }

          this.$XHR.post(postData, progress => {
            this.$store.dispatch('controlUploadAlert', {
              show: true,
              progress: this.$common.formatUploadProgress(progress.loaded, progress.total),
            })
          },
          ).then(res => {
            this.$store.dispatch('setUploadLoading', false);
            this.$store.dispatch('controlUploadAlert');
            this.$common.logData(res, '----------> XHR [SUCCESS]: UploadFile');

            if (res.data && res.data.filename) {
              let fileList = [];
              if (!this.singleImage) {
                fileList = [...this.imgList];
              }

              fileList.push(res.data.filename);
              this.$emit('update:imgList', fileList);
              if (this.dispatchUpdateOnChange === true) {
                this.$store.dispatch('setDataIsUpdated', true);
              }

              this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: '上傳成功',
                type: 'success',
                refresh: false,
                redirect: '',
              })

              document.getElementById(this.fileInputKey).value = null;
            } else {
              this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: '上傳失敗',
                type: 'error',
                refresh: false,
                redirect: '',
              })
            }
          }).catch(err => {
            this.$store.dispatch('setUploadLoading', false);
            this.$store.dispatch('controlUploadAlert');
            this.$common.error(err);
            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: '上傳失敗',
              type: 'error',
              refresh: false,
              redirect: '',
            })
          });
        }
      }
    },

    getImage(img) {
      if (img.indexOf('pdf') !== -1) {
        return require('@/assets/file-pdf-box.svg')
      } else if (img.indexOf('mp4') !== -1 || img.indexOf('webm') !== -1) {
        return require('@/assets/video.svg')
      }

      return this.$mediaPath + img;
    },
    previewImg(index) {
      if (this.$validate.DataValid(this.imgList[index])) {
        if (typeof this.imgList[index] === 'string') {
          window.open(this.$mediaPath + this.imgList[index]);
          return;
        } else if (typeof this.imgList[index] === 'object' && this.$validate.DataValid(this.imgList[index].image)) {
          window.open(this.$mediaPath + this.imgList[index].image);
          return;
        }
      }

      this.$store.dispatch('toggleAlertMessage', {
        show: true,
        message: '檔案不存在',
        type: 'error',
        refresh: false,
        redirect: '',
      });
    },
    removeImage(index) {
      const fileList = this.$common.duplicateData(this.imgList);
      fileList.splice(index, 1);
      this.$emit('update:imgList', fileList);

      if (this.dispatchUpdateOnChange === true) {
        this.$store.dispatch('setDataIsUpdated', true);
      }
    },
    addImageDes(index) {
      this.newDes = '';
      this.addDesIndex = -1;
      if (this.$validate.DataValid(this.imgList[index]) && typeof this.imgList[index] === 'object') {
        this.addDesIndex = index;
        if (this.$validate.DataValid(this.imgList[index].remark)) {
          this.newDes = this.imgList[index].remark;
        }

        this.addDesDialogOpen = true;
      }
    },
    saveImageDes(action) {
      if (action === true) {
        if (this.addDesIndex > -1 && this.$validate.DataValid(this.imgList[this.addDesIndex])) {
          const fileList = this.$common.duplicateData(this.imgList);
          fileList[this.addDesIndex].remark = this.newDes;
          this.$emit('update:imgList', fileList);
          if (this.dispatchUpdateOnChange === true) {
            this.$store.dispatch('setDataIsUpdated', true);
          }
        }
      }

      this.newDes = '';
      this.addDesIndex = -1;
      this.addDesDialogOpen = false;
    }
  },
}
</script>

<style lang="scss" scoped>
.preview-img {
  &:hover {
    cursor: pointer;
  }
}

.image-upload-display {
  display: flex;
  overflow-x: auto;
  align-items: flex-end;

  &>div:not(:first-child) {
    margin-left: 20px;
  }

  & .img-des {
    white-space: pre-wrap;
    display: -webkit-inline-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    max-width: 250px;
    -webkit-box-orient: vertical;
  }
}

.no-text-upload-btn.v-btn {
  width: 100% !important;
  height: 150px !important;
  border: 2px solid rgba(94, 86, 105, 0.141);
  min-width: unset !important;
}
</style>
